import { ChangeEvent, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputPrompt, setInputPrompt } from '../data/ui';
import { useRequest } from './useRequest';
import { filterSuggestionsMatchedByInput } from '../utils/prompts';
import {
  canShowSuggestionsSelector,
  filteredSuggestionsSelector,
  highlightedSuggestionIndexSelector,
  showingSuggestionsSelector
} from '../data/prompts/suggestions-slice';
import {
  closeAndResetSuggestions,
  enableSuggestions,
  setFilteredSuggestions,
  setHighlightedSuggestion,
  setSelectedModalSuggestion
} from '../data/prompts/suggestions-slice';
import { SelectedChatContext } from '../context/SelectedChatContext';

export default function useSuggestions() {
  const dispatch = useDispatch();
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const { autocompleteSuggestions, isModalPrompt } = useRequest({ llmKey: chat?.llm });

  const canShowSuggestions = useSelector(canShowSuggestionsSelector);
  const filteredSuggestions = useSelector(filteredSuggestionsSelector);
  const highlightedSuggestionIndex = useSelector(highlightedSuggestionIndexSelector);

  const showingSuggestions = useSelector(showingSuggestionsSelector);
  const inputPrompt = useSelector(selectInputPrompt);

  const filterByInput = useCallback(
    (inputValue: string) => {
      const filtered = filterSuggestionsMatchedByInput(inputValue, autocompleteSuggestions);
      dispatch(setFilteredSuggestions(filtered));
      if (filtered.length === 1) {
        dispatch(setHighlightedSuggestion(0));
      }
    },
    [dispatch, autocompleteSuggestions]
  );

  const suggestionUp = useCallback(() => {
    const indexToSet = highlightedSuggestionIndex > 0 ? highlightedSuggestionIndex - 1 : filteredSuggestions.length - 1;
    dispatch(setHighlightedSuggestion(indexToSet));
  }, [dispatch, highlightedSuggestionIndex, filteredSuggestions]);

  const suggestionDown = useCallback(() => {
    if (highlightedSuggestionIndex < filteredSuggestions.length - 1) {
      dispatch(setHighlightedSuggestion(highlightedSuggestionIndex + 1));
    } else {
      dispatch(setHighlightedSuggestion(0));
    }
  }, [dispatch, highlightedSuggestionIndex, filteredSuggestions]);

  const turnOnSuggestions = useCallback(() => {
    dispatch(enableSuggestions());
  }, [dispatch]);

  const turnOffSuggestions = useCallback(() => {
    dispatch(closeAndResetSuggestions(autocompleteSuggestions));
  }, [dispatch, autocompleteSuggestions]);

  const pickModalSuggestion = useCallback(
    (suggestion: string) => {
      dispatch(setSelectedModalSuggestion({ suggestion, filteredSuggestions: autocompleteSuggestions }));
    },
    [dispatch, autocompleteSuggestions]
  );

  const resolveSuggestionSelection = useCallback(
    (suggestion: string, inputElem: HTMLTextAreaElement) => {
      if (suggestion) {
        const { selectionEnd } = inputElem;
        const beforeCursor = inputPrompt.substring(0, selectionEnd);
        const slashIndex = beforeCursor.lastIndexOf('/');
        let replaceInput;

        if (isModalPrompt(suggestion)) {
          replaceInput = `${beforeCursor.substring(0, slashIndex)}${inputPrompt.substring(selectionEnd)}`;
          pickModalSuggestion(suggestion);
          dispatch(setInputPrompt(replaceInput));
        } else {
          replaceInput = `${suggestion} ${beforeCursor.substring(0, slashIndex)}${inputPrompt.substring(selectionEnd)}`;
          turnOffSuggestions();
          dispatch(setInputPrompt(replaceInput));
          inputElem.focus();
        }
      }
    },
    [turnOffSuggestions, pickModalSuggestion, inputPrompt, isModalPrompt, dispatch]
  );

  const suggestionDropDownClick = useCallback(
    (suggestion: string, inputElem: HTMLTextAreaElement) => {
      if (inputElem) {
        resolveSuggestionSelection(suggestion, inputElem);
      }
    },
    [resolveSuggestionSelection]
  );

  const autoSuggestionsInputChange = useCallback(
    (chE: ChangeEvent) => {
      if (canShowSuggestions) {
        const target = chE.target as HTMLTextAreaElement;
        const { selectionEnd, value: inputValue } = target;
        if (/(^\/.*)|(.*\/[a-zA-Z-_0-9]?$)/.test(inputValue)) {
          const beforeCursor = inputValue.substring(0, selectionEnd);
          const slashIndex = beforeCursor.lastIndexOf('/');
          const filterValue = `${inputValue.substring(slashIndex, selectionEnd)}`;
          filterByInput(filterValue);
        }
      }
    },
    [canShowSuggestions, filterByInput]
  );

  const autoSuggestionsInputKeyDown = useCallback(
    (kdE: KeyboardEvent) => {
      const target = kdE.target as HTMLTextAreaElement;
      switch (true) {
        case kdE.key === '/' && (target.selectionEnd === 0 || target.selectionEnd === target.value.length):
          turnOnSuggestions();
          break;
        case showingSuggestions && kdE.key === 'ArrowUp':
          kdE.preventDefault();
          suggestionUp();
          break;
        case showingSuggestions && kdE.key === 'ArrowDown':
          kdE.preventDefault();
          suggestionDown();
          break;
        case showingSuggestions && (kdE.key === 'ArrowLeft' || kdE.key === 'ArrowRight'):
          turnOffSuggestions();
          break;
        case showingSuggestions && kdE.key === 'Enter':
          kdE.preventDefault();
          resolveSuggestionSelection(filteredSuggestions[highlightedSuggestionIndex], target);
          break;
        case showingSuggestions && kdE.key === 'Escape':
          turnOffSuggestions();
          break;
        case showingSuggestions &&
          kdE.key === 'Backspace' &&
          target.value.length > 0 &&
          target.value[target.selectionEnd - 1] === '/':
          turnOffSuggestions();
          break;
        default:
          return true;
      }
      return false;
    },
    [
      resolveSuggestionSelection,
      showingSuggestions,
      filteredSuggestions,
      highlightedSuggestionIndex,
      suggestionUp,
      suggestionDown,
      turnOnSuggestions,
      turnOffSuggestions
    ]
  );

  return {
    autoSuggestionsInputKeyDown,
    autoSuggestionsInputChange,
    suggestionDropDownClick,
    turnOffSuggestions
  };
}
