import React, { ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavLinksContainer from './NavLinksContainer';
import NavPrompt from './NavPrompt';
import NewChat from './NewChat';
import SearchInput from './SearchInput';
import useSearch from '../hooks/useSearch';
import { useChats } from '../hooks/useChats';

type ParamsType = {
  children?: ReactNode;
};

export default function NavContent({ children }: ParamsType) {
  const { chatList } = useChats();
  const navigate = useNavigate();

  // reindex chatLog to search index
  const { reindex } = useSearch();

  const onChatDeleting = useCallback(
    (chatId: string) => {
      if (chatList.length > 1) {
        const indexInList = chatList.findIndex(({ id }) => id === chatId);
        const checkList = [...chatList];
        checkList.splice(indexInList, 1);
        const indexToSet = indexInList > 0 ? indexInList - 1 : 0;
        const { id } = checkList[indexToSet];
        navigate(`/chats/${id}`);
      }
    },
    [chatList, navigate]
  );

  useEffect(() => {
    if (chatList) {
      reindex(chatList);
    }
  }, [chatList, reindex]);

  return (
    <>
      <div className="navItems-chats">
        <SearchInput />
        <NewChat />
        <div className="navPromptWrapper chat-logs">
          {chatList?.map(({ name, files, id }) => (
            <NavPrompt
              key={`navPrompt-${name}-${id}`}
              chatId={id}
              chatPrompt={name?.substring(0, 25) || 'New chat'}
              onChatRemoving={onChatDeleting}
            />
          ))}
        </div>
      </div>
      {children}
      <NavLinksContainer />
    </>
  );
}
