import { request } from '../../data/llm/openai';
import { _raiseIfNotOk } from '../../utils/responses';

export const chatsDownloadContent = async (includeFiles: boolean) => {
  const response = await request('/chats-encrypted', { include_files: includeFiles });
  _raiseIfNotOk(response);
  const { chats, i: rec }: { chats: string; i: string } = await response.json();
  return { chats, rec };
};

export const chatsDecryptContent = async (record: string, data: string) => {
  const response = await request('/decrypt-chats-upload', {
    i: record,
    chats: data
  });
  _raiseIfNotOk(response);
  return await response.json();
};
