import { defaultLLM } from './openai';
import { claudeLLM } from './claude';
import { DEFAULT_LLM_KEY as DEFAULT_LLM_KEY_VALUE, LlmInterface, LlmsInterface } from './types';

export const DEFAULT_LLM_KEY = DEFAULT_LLM_KEY_VALUE;

// REGISTER NEW LLM MODULE HERE
class Llms implements LlmsInterface {
  private _llms: Array<[string, LlmInterface]>;
  constructor() {
    this._llms = [] as Array<[string, LlmInterface]>;
    if (defaultLLM.isAvailable()) {
      this._llms.push([DEFAULT_LLM_KEY, defaultLLM]);
    }
    if (claudeLLM.isAvailable()) {
      this._llms.push(['claude', claudeLLM]);
    }
  }
  llm(name: string): LlmInterface {
    for (let [llmName, llm] of this._llms) {
      if ((name || DEFAULT_LLM_KEY) === llmName) {
        return llm;
      }
    }
    console.log('name', name);
    throw new Error(`LLM ${name} was not found.`);
  }

  all(): Array<[string, LlmInterface]> {
    return this._llms;
  }
}

const LLMS = new Llms();

export default LLMS;
