import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetChatsQuery as useLegacyGetChatsQuery,
  useSetChatsMutation as useLegacySetChatsMutation,
  useAddChatMutation as useLegacyAddChatMutation,
  useReplaceChatMutation as useLegacyReplaceChatMutation
} from '../data/chats';
import {
  selectReadyForNewChats,
  useGetChatsQuery,
  useAddChatMutation,
  useRenameChatMutation,
  useReplaceChatMutation,
  useDeleteChatMutation,
  chatsApi,
  createEmptyFEChat,
  FEFileAssistant
} from '../data/chats-remote';
import { selectCurrentUser } from '../data/ui';
import { isEqual } from 'lodash';
import type { ChatType } from '../data/chats-type';
import type { ChatValuesType } from '../data/chats-type';
import { useNavigate } from 'react-router-dom';

export const useDefaultApiChat = () => {
  const readyForNewChats = useSelector(selectReadyForNewChats);
  // @ts-ignore
  const { data: remoteChatList }: { data: Array<ChatType> } = useGetChatsQuery();
  const [addChat] = useAddChatMutation();
  const [checkedDefaultChat, setCheckedDefaultChat] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkedDefaultChat) {
      if (readyForNewChats && remoteChatList) {
        setCheckedDefaultChat(() => true);
        const hasAnyChat = remoteChatList.length !== 0;
        if (!hasAnyChat) {
          addChat(createEmptyFEChat())
            .then(({ data: { id } }) => {
              navigate(`/chats/${id}`);
            })
            .catch((addError) => {
              console.error(addError);
            });
        }
      }
    }
  }, [readyForNewChats, remoteChatList, addChat, checkedDefaultChat, navigate]);

  useEffect(() => {
    if (remoteChatList && remoteChatList.length === 0) {
      setCheckedDefaultChat(() => false);
    }
  }, [remoteChatList]);
};

type UseChatsResultType = {
  addChatApi: {
    (chat: ChatValuesType): any;
  };
  chatList: Array<ChatType>;
  clearChatsApi: {
    (): void;
  };
  deleteChatApi: {
    (chatId: string | number): Promise<unknown>;
  };
  getChat: {
    (chatIndex: number): ChatType | undefined;
  };
  getChatById: {
    (chatId: string): ChatType | undefined;
  };
  loadChatsApi: {
    (data: Array<ChatType>): void;
  };
  newChatIndex: number | null;
  renameChatsApi: {
    (id: number | string, name: string): Promise<unknown>;
  };
  replaceChat: {
    (chat: ChatType): Promise<unknown> & { unwrap(): Promise<unknown> };
  };
  replaceChatFileId: {
    (chat: ChatType, oldId: string, newId: string): Promise<unknown>;
  };
};

export const useChats = (): UseChatsResultType => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const readyForNewChats = useSelector(selectReadyForNewChats);
  const currentUser = useSelector(selectCurrentUser);

  const [addChatLegacy] = useLegacyAddChatMutation();
  const [addChat] = useAddChatMutation();

  const [replaceChatLegacy] = useLegacyReplaceChatMutation();
  const [replaceChatRemote] = useReplaceChatMutation();
  const [renameChat] = useRenameChatMutation();

  const { data: legacyChatList } = useLegacyGetChatsQuery({ userId: currentUser.uid });
  // @ts-ignore
  const { data: remoteChatList } = useGetChatsQuery();
  const [chatList, setChatList] = useState<Array<ChatType>>([]);

  const [deleteChat] = useDeleteChatMutation();
  const [legacySetChats] = useLegacySetChatsMutation();
  const [newChatIndex, setNewChatIndex] = useState<number | null>(null);

  const setChatsReversed = useCallback((chatList: Array<ChatType>) => {
    setChatList(() => [...chatList].reverse());
  }, []);

  const addChatApi = useCallback(
    (chat: ChatType) => {
      if (readyForNewChats) {
        return addChat(chat);
      } else {
        return addChatLegacy({ ...chat });
      }
    },
    [addChat, addChatLegacy, readyForNewChats]
  );

  const getChat = useCallback(
    (chatIndex: number) => {
      return (chatList || [])[chatIndex];
    },
    [chatList]
  );

  const getChatById = useCallback(
    (chatId: string) => {
      return chatList.find(({ id }) => id === chatId);
    },
    [chatList]
  );

  const renameChatsApi = useCallback(
    (id: number | string, name: string) => {
      if (readyForNewChats) {
        return new Promise<void>((resolve) => {
          renameChat({ id, newName: name });
          resolve();
        });
      } else {
        const chat = getChat(id as number);
        return replaceChatLegacy({ userId: currentUser.uid, chatIndex: id, payload: { ...chat, name } });
      }
    },
    [currentUser.uid, getChat, readyForNewChats, renameChat, replaceChatLegacy]
  );

  const replaceChat = useCallback(
    (chat: ChatType) => {
      return replaceChatRemote(chat);
    },
    [replaceChatRemote]
  );

  const replaceChatFileId = useCallback(
    async (chat: ChatType, oldFileId: string, newFileId: string) => {
      if (!chat.fileAssistant) {
        throw new Error('Chat without file assistant has no use here.');
      }
      const fileAssistant = chat.fileAssistant as FEFileAssistant;
      const { vectorStoreFiles } = fileAssistant;
      return await replaceChatRemote({
        ...chat,
        fileAssistant: {
          ...fileAssistant,
          vectorStoreFiles: vectorStoreFiles.map(({ fileId, ...vectorStoreFile }) => ({
            ...vectorStoreFile,
            fileId: fileId !== oldFileId ? fileId : newFileId
          }))
        }
      });
    },
    [replaceChatRemote]
  );

  const deleteChatApi = useCallback(
    (chatId: string | number) => {
      return deleteChat(chatId as string);
    },
    [deleteChat]
  );

  const clearChatsApi = useCallback(() => {
    if (readyForNewChats) {
      for (const remoteChat of remoteChatList as Array<ChatType>) {
        deleteChat(remoteChat.id as string);
      }
    } else {
      legacySetChats({ userId: currentUser.uid, chats: [] });
    }
  }, [readyForNewChats, remoteChatList, deleteChat, legacySetChats, currentUser.uid]);

  const loadChatsApi = useCallback(
    (chatsData: Array<ChatType>) => {
      if (readyForNewChats) {
        Promise.all(chatsData.map((chatData) => addChat(chatData)))
          .then((chats) => {
            dispatch(chatsApi.util.resetApiState());
            if (chats.length) {
              navigate(`/chats/${chats[chats.length - 1].data.id}`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        const toLoad = chatsData.filter(
          (item) => !(legacyChatList as Array<ChatType>)?.some((chat) => isEqual(chat, item))
        );
        if (toLoad) {
          legacySetChats({ userId: currentUser.uid, chats: [...legacyChatList, ...toLoad] });
        }
      }
    },
    [readyForNewChats, addChat, dispatch, navigate, legacyChatList, legacySetChats, currentUser.uid]
  );

  useEffect(() => {
    if (remoteChatList && legacyChatList) {
      const list: Array<ChatType> = readyForNewChats ? remoteChatList : legacyChatList;
      const noNameIndex = list.findIndex((chat) => chat.name === null);
      setNewChatIndex(() => (noNameIndex === -1 ? null : noNameIndex));
    }
  }, [readyForNewChats, newChatIndex, remoteChatList, legacyChatList]);

  useEffect(() => {
    if (remoteChatList && legacyChatList) {
      setChatsReversed(readyForNewChats ? remoteChatList : legacyChatList);
    }
  }, [readyForNewChats, remoteChatList, legacyChatList, setChatsReversed]);

  return {
    addChatApi,
    chatList,
    clearChatsApi,
    deleteChatApi,
    getChat,
    getChatById,
    loadChatsApi,
    newChatIndex,
    renameChatsApi,
    replaceChat,
    replaceChatFileId
  };
};
