import React, { ChangeEvent, ReactNode, useCallback, useEffect, useId, useRef, useState } from 'react';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { AiOutlineCheck } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import clsx from 'clsx';
import { createPortal } from 'react-dom';

import { isEqual } from 'lodash';
import NavLinks from '../NavLink';
import ClearChats from '../ClearChats';
import createOutSideHandler from '../../utils/hot-toggle';
import { CloseChatsType, createFileReader, frontendDownloadFile, LegacyOpenChatsType } from '../../utils/chats-json';
import { useChats } from '../../hooks/useChats';
import Logout from '../Logout';
import { chatsDownloadContent, chatsDecryptContent } from './requests';
import UploadProgressModal, { ChatsDecryptedResponse } from './UploadProgressModal';

type ParamsType = {
  to?: string;
  svg: ReactNode;
  text: string;
};

export default function NavUserExpandable({ to, svg, text }: ParamsType) {
  const { clearChatsApi, loadChatsApi, chatList: chats } = useChats();

  const toggleRef = useRef(null);
  const wrapperRef = useRef(null);
  const [showChildren, setShowChildren] = useState(false);
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const [confirmingClears, setConfirmingClears] = useState(false);
  const [downloadOnClear, setDownloadOnClear] = useState(true);
  const [showUploadChatsProgress, setShowUploadChatsProgress] = useState(false);
  const [decryptedChatsResponse, setDecryptedChatsResponse] = useState<null | ChatsDecryptedResponse>(null);
  const downloadOnClearId = useId();

  const handleChatsDownload = useCallback(
    (withFiles = true) => {
      let resultPromise: Promise<boolean>;
      if (chats?.length > 0) {
        const downloadedAt = new Date();
        const downloadedAtLocale = downloadedAt.toLocaleString().replace(/\//g, '-').replace(/, /g, ' ');
        const fileName = `Chats ${downloadedAtLocale}`;

        resultPromise = new Promise<boolean>((resolve, reject) => {
          chatsDownloadContent(withFiles)
            .then(({ chats, rec }) => {
              const downloadData = {
                downloadedAt: downloadedAt.toISOString(),
                data: chats,
                re: rec
              };
              frontendDownloadFile(fileName, downloadData);
              resolve(true);
            })
            .catch((error) => {
              console.error(error);
              resolve(false);
            });
        });
      } else {
        resultPromise = Promise.resolve(true);
      }
      setShowChildren(() => false);
      return resultPromise;
    },
    [chats]
  );

  const handleChatsDownloadClick = useCallback(() => {
    handleChatsDownload(true)
      .then(() => {
        console.log('Download complete');
      })
      .catch((error) => {
        console.error(error);
      });
  }, [handleChatsDownload]);

  const onClearChatClick = useCallback(() => {
    setConfirmingClears(() => true);
  }, []);

  const onClearChat = useCallback(
    (e: Event) => {
      e.preventDefault();

      const promiseStep = downloadOnClear ? handleChatsDownload(true) : Promise.resolve(true);
      promiseStep.then((canGoFurther) => {
        if (canGoFurther) {
          clearChatsApi();
          setShowChildren(() => false);
        }
      });
    },
    [downloadOnClear, clearChatsApi, handleChatsDownload]
  );

  const onCancelClearChat = useCallback(() => {
    setConfirmingClears(() => false);
  }, []);

  const handleChatsUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target?.files || [];
      let pendingFiles = files.length || 0;
      setShowChildren(() => false);

      // Wrapping in timeout just for the case when user has no chats, so "Clear" and "Download" items are not
      // displayed. After we upload chats, two things happen:
      // 1) the mentioned menu items are added to menu;
      // 2) the menu is closed.
      // Now between 1) and 2) there is a gap in 200ms because of how menu is closed (smooth effect). So we wrap 1)
      // into same timeout.

      setTimeout(() => {
        for (let file of files) {
          const reader = createFileReader<LegacyOpenChatsType | CloseChatsType>(
            (data) => {
              let fileData: LegacyOpenChatsType | CloseChatsType = data as LegacyOpenChatsType;
              if (fileData.chatsData) {
                // Legacy support
                const toLoad = fileData.chatsData
                  .filter((item) => item.name)
                  .filter((item) => !chats?.some((chat) => isEqual(chat, item)));
                if (toLoad?.length > 0) {
                  loadChatsApi(toLoad);
                }
              } else {
                setShowUploadChatsProgress(() => true);
                fileData = data as CloseChatsType;
                if (!fileData.re || !fileData.data) {
                  throw new Error('Cannot read the file');
                }
                chatsDecryptContent(fileData.re, fileData.data)
                  .then((response) => {
                    setDecryptedChatsResponse(() => response as ChatsDecryptedResponse);
                  })
                  .catch((error) => {
                    console.error(error);
                    setShowUploadChatsProgress(() => false);
                  });
              }
            },
            //FIXME:
            // eslint-disable-next-line no-loop-func
            () => {
              if (--pendingFiles === 0) {
                event.target.value = '';
              }
            }
          );
          reader.readAsText(file);
        }
      }, 200);
    },
    [chats, loadChatsApi]
  );

  const onChatsUploadClick = useCallback(() => {
    if (uploadFileRef?.current) {
      uploadFileRef.current.click();
    }
  }, [uploadFileRef]);

  const onClick = useCallback(() => {
    setShowChildren((shown) => !shown);
  }, [setShowChildren]);

  const onDownloadOnClearChange = useCallback(() => {
    setDownloadOnClear((download) => !download);
  }, []);

  const onUploadProgressModalClose = useCallback(() => {
    setTimeout(() => {
      setShowUploadChatsProgress(() => false);
      setDecryptedChatsResponse(() => null);
    }, 500);
  }, []);

  useEffect(() => {
    if (!showChildren) {
      setConfirmingClears(() => false);
      setDownloadOnClear(() => true);
    }
  }, [showChildren]);

  useEffect(() => {
    return createOutSideHandler(wrapperRef, () => setShowChildren(() => false));
  }, [wrapperRef, setShowChildren]);

  const childrenWrapperClsx = clsx('navPromptChildren-wrapper', showChildren && 'visible');

  return (
    <div className={'navLinkExpandable'} ref={wrapperRef}>
      <div ref={toggleRef}>
        <NavLinks svg={svg} link={to} text={text} onClick={onClick} />
      </div>
      <div className={childrenWrapperClsx}>
        {!confirmingClears && (
          <div className={'navPromptChildren'}>
            {chats?.some(({ name }) => name !== null) && (
              <NavLinks svg={<ClearChats />} text="Clear Conversations" onClick={onClearChatClick} />
            )}
            {chats?.length > 0 && (
              <NavLinks svg={<FaDownload />} text={'Download chats'} onClick={handleChatsDownloadClick} />
            )}
            <NavLinks svg={<FaUpload />} text={'Upload chats'} onClick={onChatsUploadClick} />
            <input
              type="file"
              accept={'application/json'}
              onChange={handleChatsUpload}
              style={{ display: 'none' }}
              ref={uploadFileRef}
            />
            <div className="navPromptChildren-delimeter"></div>

            <Logout />
          </div>
        )}
        {showUploadChatsProgress &&
          createPortal(
            <UploadProgressModal decryptResponse={decryptedChatsResponse} onModalClose={onUploadProgressModalClose} />,
            document.body
          )}
        {confirmingClears && (
          <div className="navPromptChildren">
            <div className="navConfirmClearChats">
              <div className="navConfirmClearChats-question">Sure you want to clear all chats?</div>

              <form
                className="navConfirmClearChats-downloadForm"
                onSubmit={onClearChat as unknown as React.FormEventHandler<HTMLFormElement>}
              >
                <label>
                  <input
                    type="checkbox"
                    id={downloadOnClearId}
                    checked={downloadOnClear}
                    onChange={onDownloadOnClearChange}
                  />
                  <span>Download chats as file</span>
                </label>

                <div className="clearActions">
                  <button type="submit">
                    <AiOutlineCheck color="white" />
                  </button>
                  <button type="button" onClick={onCancelClearChat}>
                    <RxCross2 color="white" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
