import React from 'react';
import DocsBot, { ParamsType } from './DocsBot/DocsBot';

function HRPayroll({ onClose, onDone }: ParamsType) {
  return (
    <DocsBot onClose={onClose} onDone={onDone} modalTitle="Payroll" modalDescription="Anything about HR/Payroll" />
  );
}

export default HRPayroll;
