import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from './useRequest';
import llms, { DEFAULT_LLM_KEY } from '../data/llm';
import { selectCurrentUser, setInputPrompt } from '../data/ui';
import { useChats } from './useChats';
import { ChatEntryType, ChatLogType, ChatType } from '../data/chats-type';
import { discoverCommandExecutor } from '../data/llm/utils';
import { CommandExecutor, LlmInterface } from '../data/llm/types';
import { SelectedChatContext } from '../context/SelectedChatContext';

export type SendInputParams = {
  inputPrompt: string;
  onSent?: () => void;
  onResponse?: () => void;
  modifyChatLog?: (chatLog: ChatLogType) => ChatLogType;
};

export function useInput() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { replaceChat } = useChats();
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const chatLLMKey = chat?.llm || DEFAULT_LLM_KEY;
  const {
    call: apiCall,
    responseFromAPI,
    setResponseFromAPI,
    setCanStopResponse,
    setCurrentBotMessage
  } = useRequest({ llmKey: chatLLMKey });
  const llm: LlmInterface = llms.llm(chatLLMKey);

  const sendInput = useCallback(
    ({ inputPrompt, onSent, onResponse, modifyChatLog }: SendInputParams) => {
      // Set responseFromAPI to true before making the fetch request
      setResponseFromAPI(true);
      console.log('inputPrompt', inputPrompt);

      const chatLog = chat?.chatLog || [];
      const originChatLog = modifyChatLog ? modifyChatLog(chatLog) : chatLog;
      const chatName: string = chat?.name || inputPrompt;
      const newChatLogEntry: ChatEntryType = { chatPrompt: inputPrompt, botMessage: 'Loading...' };
      const company = currentUser.email.split('@')[1];
      const files = chat?.fileAssistant?.vectorStoreFiles || [];
      const selectedFiles = files.filter(({ selected }) => selected !== false);

      const chatValuesData = {
        ...(chat as ChatType),
        // files,
        name: chatName,
        llm: chatLLMKey,
        chatLog: [...originChatLog, newChatLogEntry]
      };
      const commandExecutor: CommandExecutor = discoverCommandExecutor(llm, {
        inputPrompt,
        chatLog: originChatLog,
        uid: currentUser.uid,
        company,
        files: selectedFiles.map(({ fileId, fileName }) => ({ fileId, fileName })),
        chatId: chat?.id as string
      });

      const chatValuesPromise = commandExecutor.beforeReplaceChat
        ? commandExecutor.beforeReplaceChat(chatValuesData)
        : Promise.resolve(chatValuesData);

      chatValuesPromise.then((chatValues) => {
        replaceChat(chatValues)
          .unwrap()
          .then(() => {
            setCanStopResponse(true);
            apiCall(commandExecutor)
              .then((botMessage) => {
                if (onResponse) {
                  onResponse();
                }
                if (botMessage) {
                  replaceChat({
                    ...chatValues,
                    chatLog: [...originChatLog, { ...newChatLogEntry, botMessage }]
                  })
                    .unwrap()
                    .then(() => {
                      setCurrentBotMessage('');
                    });
                }
                setResponseFromAPI(false);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setCanStopResponse(false);
              });

            if (onSent) {
              onSent();
            }
          })
          .catch((error) => {
            console.log('Error in replace');
            console.error(error);
          });
      });
    },
    [
      setResponseFromAPI,
      chat,
      currentUser.email,
      currentUser.uid,
      chatLLMKey,
      llm,
      replaceChat,
      setCanStopResponse,
      apiCall,
      setCurrentBotMessage
    ]
  );

  const runInputPrompt = useCallback(
    ({ inputPrompt, onSent, onResponse, modifyChatLog }: SendInputParams) => {
      if (!responseFromAPI && inputPrompt.trim() !== '') {
        sendInput({ inputPrompt, onSent, onResponse, modifyChatLog });
      }
      dispatch(setInputPrompt(''));
    },
    [responseFromAPI, sendInput, dispatch]
  );

  return { runInputPrompt };
}
