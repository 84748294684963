import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import clsx from 'clsx';
import { createEscapeHandler } from '../utils/hot-toggle';
import './ModalSlide.scss';

type ParamsType = {
  blockClosing?: boolean;
  bodyClassName?: string | undefined;
  children: ReactNode;
  isOpened: boolean;
  hiddenClassName?: string;
  modalClassNames: object;
  onModalClose: () => void;
  title: string;
};

function ModalSlide({
  children,
  blockClosing = false,
  isOpened,
  onModalClose,
  title,
  modalClassNames = {},
  hiddenClassName = 'hidden-right',
  bodyClassName = undefined
}: ParamsType) {
  const [modalHiddenRight, setModalHiddenRight] = useState(isOpened);
  const backdropClsx = clsx({
    'modalslide-backdrop': true,
    'modalslide-backdrop-transparent': modalHiddenRight
  });
  const modalClx = clsx({
    'modalslide-modal': true,
    [hiddenClassName]: modalHiddenRight,
    ...modalClassNames
  });

  const closeModal = useCallback(() => {
    if (!blockClosing) {
      setModalHiddenRight(() => true);
      setTimeout(() => {
        if (onModalClose) {
          onModalClose();
        }
      }, 200);
    }
  }, [onModalClose, blockClosing]);

  useEffect(() => {
    setTimeout(() => {
      setModalHiddenRight(() => false);
    }, 100);
  }, []);

  useEffect(() => {
    if (!isOpened && !blockClosing) {
      closeModal();
    }
  }, [isOpened, closeModal, blockClosing]);

  useEffect(() => {
    return !blockClosing ? createEscapeHandler(closeModal) : () => {};
  }, [closeModal, blockClosing]);

  return (
    <div>
      <div className={backdropClsx} onClick={closeModal}></div>
      <div className={modalClx}>
        <div className={clsx('modalslide-body', bodyClassName)}>
          <div className="modalslide-top-row">
            <div className="modalslide-title">{title}</div>
            <button className={clsx('modalslide-close', blockClosing && 'disabled')} type="button" onClick={closeModal}>
              <RxCross2 color="white" className="iconClick" style={{ fontSize: '25px' }} />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalSlide;
