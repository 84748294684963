import React, { EventHandler, useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputPrompt, setInputPrompt } from '../data/ui';
import useSuggestions from '../hooks/useSuggestions';
import { selectedModalSuggestionSelector } from '../data/prompts/suggestions-slice';
import AutocompleteSuggestions from './AutocompleteSuggestions';
import SendIcon from './SendIcon';
import { useInput } from '../hooks/useInput';
import { ChatFileUploadContext } from '../context/ChatFileUpload';
import InputAreaUploadedFiles from './InputAreaUploadedFiles';
import { SelectedChatContext } from '../context/SelectedChatContext';

function InputArea() {
  const dispatch = useDispatch();
  const inputPrompt = useSelector(selectInputPrompt);

  const { uploadFiles, isUploadingFile, showFileUploads } = useContext(ChatFileUploadContext);

  const selectedModalSuggestion = useSelector(selectedModalSuggestionSelector);
  const { autoSuggestionsInputChange, autoSuggestionsInputKeyDown, suggestionDropDownClick } = useSuggestions();
  const { selectedChat: chat } = useContext(SelectedChatContext);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { runInputPrompt } = useInput();

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || !files.length) return;

      uploadFiles(files)
        .then(() => {
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [uploadFiles]
  );

  // hide the keyboard in mobile devices
  const blurTextarea = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.blur();
    }
  }, [textAreaRef]);

  const onInputSent = useCallback(() => {
    blurTextarea();
  }, [blurTextarea]);

  const handleSubmit: EventHandler<any> = useCallback(
    (e: Event) => {
      e.preventDefault();

      runInputPrompt({ inputPrompt, onSent: onInputSent });
    },
    [inputPrompt, runInputPrompt, onInputSent]
  );

  const handleInput = useCallback(() => {
    if (textAreaRef.current) {
      const textarea = textAreaRef.current;
      dispatch(setInputPrompt(textarea.value));
      // Show autocomplete suggestions when the user types '/' and hide them otherwise.
      textarea.style.height = 'auto'; // Reset height to auto to shrink if needed
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
    }
  }, [dispatch, textAreaRef]);

  const onTextAreaKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (autoSuggestionsInputKeyDown(e)) {
        if (e.key === 'Enter' && !e.shiftKey) {
          handleSubmit(e);
        }
      }
    },
    [autoSuggestionsInputKeyDown, handleSubmit]
  );

  const onSuggestionClick = useCallback(
    (suggestion: string) => {
      if (textAreaRef) {
        suggestionDropDownClick(suggestion, textAreaRef?.current as HTMLTextAreaElement);
      }
    },
    [suggestionDropDownClick, textAreaRef]
  );

  useEffect(() => {
    handleInput(); // Adjust height on component mount
  }, [handleInput]);

  useEffect(() => {
    if (textAreaRef?.current && !selectedModalSuggestion) {
      textAreaRef.current.focus();
    }
  }, [selectedModalSuggestion, textAreaRef]);

  return (
    <div className="mainInputWrapper">
      <form onSubmit={handleSubmit}>
        <div className="inputPromptWrapper">
          {showFileUploads && (
            <>
              <button
                type="button"
                className="fileUploadButton iconClick"
                onClick={() => fileInputRef.current?.click()}
              >
                <svg
                  fill="#ADACBF"
                  width={35}
                  height={35}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#41414e"
                  strokeWidth={2}
                >
                  <title>Upload File</title>
                  <path
                    d="M6.346,16 C5.009,16 4,14.907 4,13.725 L4,3.99799991 C4,1.63391113 5.25378418,0 7.69795109,0 L8.3671875,0 C11.046,0 12,1.56054688 12,3.99799991 L12,11.0050049 L11.046,11.0050049 L11.046,3.99799991 C11.046,2.4140625 10.4089355,1 8.3671875,1 L7.68199992,1 C5.87280273,1 5,2.31750488 5,3.99799991 L5,13.725 C5,14.463 5.448,14.999 6.345,14.999 L7.683,14.999 C8.535,14.999 9.062,14.511 9.062,13.725 L9.062,5.756 C9.062,5.225 8.98100008,5.03984473 7.94300008,4.99084473 C6.88400008,5.04284473 7,5.262 7,5.756 L6.99999995,10.0100098 L5.99899995,10.0100098 L5.999,5.756 C5.999,4.635 6.635,4.06 7.943,3.998 C9.249,4.058 10,4.616 10,5.756 L10,13.725 C10,14.947 8.966,16 7.682,16 L6.346,16 Z"
                    stroke="none"
                  ></path>
                </svg>
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                multiple={true}
              />
              {isUploadingFile && <div className="uploading-indicator">Uploading file...</div>}

              {/*{isRemovingFile && (*/}
              {/*  <div className="uploading-indicator">*/}
              {/*    Removing {selectedUploadedFileIds.length === 1 ? 'file' : 'files'}*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*{uploadSuccessMessage && <div className="upload-success-message">{uploadSuccessMessage}</div>}*/}

              <InputAreaUploadedFiles chat={chat} />
            </>
          )}

          <textarea
            ref={textAreaRef}
            name="inputPrompt"
            id=""
            className="inputPromptTextarea"
            autoComplete="off"
            rows={1}
            value={inputPrompt}
            onChange={(e) => {
              autoSuggestionsInputChange(e);
              handleInput();
            }}
            onKeyDown={onTextAreaKeyDown as unknown as React.KeyboardEventHandler<HTMLTextAreaElement>}
            autoFocus
            disabled={!chat}
            style={{
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          />
          <button className="iconClick" aria-label="form submit" type="submit" disabled={!chat || isUploadingFile}>
            <SendIcon size={22} strokeWidth={0} />
          </button>
        </div>
      </form>
      <AutocompleteSuggestions onSuggestionClick={onSuggestionClick} />
    </div>
  );
}

export default InputArea;
