import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChatType } from '../data/chats-type';
import { createEmptyFEChat } from '../data/chats-remote';
import { useChats } from '../hooks/useChats';

type StateType = {
  selectedChat: ChatType;
  initNavigationDone: boolean;
  markInitNavigationDone: { (): void };
};

const initialState: StateType = {
  selectedChat: createEmptyFEChat(),
  initNavigationDone: false,
  markInitNavigationDone: () => {}
};

export const SelectedChatContext = createContext<StateType>(initialState);

type ProviderParamsType = {
  children: ReactNode;
};

export function SelectedChatProvider({ children }: ProviderParamsType) {
  const { chatList } = useChats();
  const [selectedChat, setSelectedChat] = useState(createEmptyFEChat());
  const [initNavigationDone, setInitNavigationDone] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const markInitNavigationDone = useCallback(() => {
    setInitNavigationDone(() => true);
  }, []);

  useEffect(() => {
    const chatId = params.chat_id;
    let chatToSet: ChatType;
    if (chatId) {
      const foundChat = chatList.find(({ id }) => id === chatId) as ChatType | undefined;
      if (foundChat) {
        chatToSet = foundChat;
      } else {
        chatToSet = { ...createEmptyFEChat(), id: '' };
      }
    } else {
      if (chatList.length) {
        navigate(`/chats/${chatList[0].id}`);
        return () => {};
      }
      chatToSet = { ...createEmptyFEChat(), id: '' };
    }

    setSelectedChat(() => chatToSet);
  }, [params, chatList, navigate]);

  const value = useMemo(
    () => ({
      selectedChat,
      initNavigationDone,
      markInitNavigationDone
    }),
    [selectedChat, initNavigationDone, markInitNavigationDone]
  );

  return <SelectedChatContext.Provider value={value}>{children}</SelectedChatContext.Provider>;
}
