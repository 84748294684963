import { useCallback, useContext, useEffect, useState } from 'react';
import { getOptions } from '../data/llm/utils';
import LLMS from '../data/llm';
import { DEFAULT_LLM_KEY } from '../data/llm/types';
import { useChats } from '../hooks/useChats';

import NextDropdown from './Dropdown';
import type { ChatType } from '../data/chats-type';
import { SelectedChatContext } from '../context/SelectedChatContext';

const LLMOptions = getOptions(LLMS);

function getLLMLabel(llmKey: string | undefined) {
  return llmKey && LLMS.llm(llmKey as string).NAME;
}

const canChangeLLM = (chat: ChatType | null) => {
  return !chat || ((!chat.files || chat.files.length === 0) && !chat.fileAssistant && chat.chatLog.length === 0);
};

export default function LLMSwitcher() {
  const { replaceChat } = useChats();
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const [llmCanBeChanged, setLlmCanBeChanged] = useState(canChangeLLM(chat));

  const setLLMToChat = useCallback(
    (llmKey: string) => {
      if (chat.id) {
        replaceChat({
          ...chat,
          llm: llmKey
        })
          .unwrap()
          .then(() => {});
      }
    },
    [replaceChat, chat]
  );

  useEffect(() => {
    setLlmCanBeChanged(() => canChangeLLM(chat));
  }, [chat]);

  return (
    <div className="llm-switcher">
      {!llmCanBeChanged ? (
        <div className="llm-label">LLM: {getLLMLabel(chat?.llm || DEFAULT_LLM_KEY)}</div>
      ) : (
        <NextDropdown
          title={getLLMLabel(chat?.llm) || 'Choose LLM (optional)'}
          options={LLMOptions.map(({ key, label }) => ({
            label,
            onClick: () => setLLMToChat(key)
          }))}
        />
      )}
    </div>
  );
}
