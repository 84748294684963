import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { selectShowMenu, setShowMenu } from '../data/ui';
import NavContent from '../components/NavContent';
import { createEscapeHandler } from '../utils/hot-toggle';
import { useRequest } from '../hooks/useRequest';
import SyncInfo from '../components/SyncInfo';
import { useSync } from '../hooks/useSync';
import { useDefaultApiChat } from '../hooks/useChats';
import ChatsPage from './ChatsPage';

export default function HomePage() {
  useDefaultApiChat();
  const { canStopResponse } = useRequest();
  const { safeStartSync } = useSync();

  const dispatch = useDispatch();
  const showMenu = useSelector(selectShowMenu);
  const [displayedMenu, setDisplayedMenu] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);

  const onOpenNavMenuClick = useCallback(() => {
    dispatch(setShowMenu(true));
  }, [dispatch]);

  const onCloseNavMenuClick = useCallback(() => {
    dispatch(setShowMenu(false));
  }, [dispatch]);

  useEffect(() => {
    return createEscapeHandler(() => {
      if (showMenu) {
        dispatch(setShowMenu(false));
      }
    });
  }, [showMenu, dispatch]);

  useEffect(() => {
    if (showMenu) {
      setDisplayedMenu(() => true);
      setTimeout(() => {
        setOpenedMenu(() => true);
      }, 1);
    } else {
      setOpenedMenu(() => false);
      setTimeout(() => {
        setDisplayedMenu(() => false);
      }, 200);
    }
  }, [showMenu]);

  useEffect(() => {
    safeStartSync();
  }, [safeStartSync]);

  return (
    <>
      <header>
        <div className="menu">
          <button onClick={onOpenNavMenuClick}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>{process.env.REACT_APP_COMPANY} Bot</h1>
      </header>
      <main>
        <SyncInfo />
        {displayedMenu && !canStopResponse && (
          <nav className={clsx({ opened: openedMenu })}>
            <div className="navItems">
              <NavContent />
            </div>
            <div className="navCloseIcon" onClick={onCloseNavMenuClick}>
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
                stroke="#fff"
                width={42}
                height={42}
              >
                <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
              </svg>
            </div>
          </nav>
        )}
        <aside className={clsx('sideMenu fixed-width', canStopResponse && 'disabled')}>
          <NavContent />
        </aside>
        <ChatsPage />
      </main>
    </>
  );
}
