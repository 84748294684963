import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '../reauth';

export type PromptAccessRecord = {
  prompt: string;
};

export const promptsAccessApi = createApi({
  reducerPath: 'promtsAccessApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['LIST-prompts-access'],
  endpoints: (builder) => ({
    getPromptsAccessList: builder.query({
      query: () => ({ url: '/user-prompts_access', method: 'get' })
    })
  })
});

export const { useGetPromptsAccessListQuery } = promptsAccessApi;
