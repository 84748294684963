import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import ModalSlide from '../ModalSlide';
import { BEChatType, chatsApi, chatsFromRemote, FEFileAssistant, VectorStoreFile } from '../../data/chats-remote';
import ProgressLoaderCircle from '../ProgressLoaderCircle';
import { checkVectorStoreRequest } from '../../data/llm/openai';
import { useChats } from '../../hooks/useChats';
import { uploadPagesRequest } from '../../context/ChatFileUpload';
import { progressPercents } from '../../utils/math';

type FileInResponse = { filename: string; file_content: string; selected: boolean };

type ChatsDecryptedItem = {
  chat: Omit<Omit<BEChatType, 'files'>, 'azure_file_assistant'>;
  files: null | Array<FileInResponse>;
};

export type ChatsDecryptedResponse = {
  result_list: Array<ChatsDecryptedItem>;
};

export type ChatsUploadProgress = {
  done: number;
  total: number;
  workingOnChatName: string;
  errorMessage: string;
};

type Params = {
  decryptResponse: null | ChatsDecryptedResponse;
  onModalClose: { (): void };
};

const uploadFiles = (vectorStoreId: string, files: Array<FileInResponse>) => {
  return new Promise<Array<VectorStoreFile>>((resolve, reject) => {
    const result: Array<VectorStoreFile> = [];
    const uploadFile = () => {
      if (files.length === 0) {
        resolve(result);
      } else {
        const { filename, file_content, selected } = files.shift() as FileInResponse;
        uploadPagesRequest(filename, vectorStoreId, [file_content])
          .then(({ vectorStoreFileId }) => {
            result.push({ fileId: vectorStoreFileId, fileContent: file_content, fileName: filename, selected });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            uploadFile();
          });
      }
    };
    uploadFile();
  });
};

function UploadProgressModal({ decryptResponse, onModalClose }: Params) {
  const { addChatApi, replaceChat } = useChats();
  const dispatch = useDispatch();
  const isUploading = decryptResponse === null;
  const [canCloseModal, setCanCloseModal] = useState(false);
  const [chatUploadProgress, setChatUploadProgress] = useState<ChatsUploadProgress>({
    done: 0,
    total: 0,
    workingOnChatName: '',
    errorMessage: ''
  });

  const runChatsUpload = useCallback(
    (decryptedResponseData: ChatsDecryptedResponse) => {
      const { result_list } = decryptedResponseData;
      const resultList = [...result_list];

      setChatUploadProgress((progress) => ({ ...progress, total: resultList.length }));

      const createChatsByList = new Promise((resolve, reject) => {
        const createChat = () => {
          if (resultList.length === 0) {
            setChatUploadProgress((progress) => ({ ...progress, workingOnChatName: '' }));
            resolve(null);
          } else {
            const { chat, files } = resultList.shift() as ChatsDecryptedItem;
            const chatData = chatsFromRemote({
              ...chat,
              azure_file_assistant: null,
              files: []
            });
            setChatUploadProgress((info) => ({
              ...info,
              workingOnChatName: chatData.name
            }));
            addChatApi(chatData)
              .unwrap()
              .then((createdChatBE: BEChatType) => {
                const createdChat = chatsFromRemote(createdChatBE);
                const filesPromise = Promise.resolve(
                  files === null || !files.length
                    ? true
                    : new Promise((resolveFiles, rejectFiles) => {
                        checkVectorStoreRequest(createdChat.id)
                          .then(({ assistantId, threadId, vectorStoreId }) => {
                            uploadFiles(vectorStoreId, files as FileInResponse[])
                              .then((vectorStoreFiles: Array<VectorStoreFile>) => {
                                const fileAssistant: FEFileAssistant = {
                                  assistantId,
                                  threadId,
                                  vectorStoreId,
                                  vectorStoreFiles
                                };
                                replaceChat({ ...createdChat, fileAssistant })
                                  .unwrap()
                                  .then(() => {
                                    resolveFiles(true);
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    rejectFiles(error);
                                  });
                              })
                              .catch((error) => {
                                rejectFiles(error);
                              });
                            // uploadPagesRequest()
                          })
                          .catch((vsError) => {
                            rejectFiles(vsError);
                          });
                      })
                );
                filesPromise
                  .then(() => {
                    setChatUploadProgress(({ errorMessage, ...progress }) => ({ ...progress, errorMessage: '' }));
                    setChatUploadProgress(({ done, ...progress }) => ({ ...progress, done: done + 1 }));
                    createChat();
                  })
                  .catch((error) => {
                    setChatUploadProgress(({ errorMessage, ...progress }) => ({
                      ...progress,
                      errorMessage: "Error uploading chat's file"
                    }));
                    console.error(error);
                    createChat();
                  });
              })
              .catch((error: Error) => {
                reject(error);
                setChatUploadProgress(({ errorMessage, ...progress }) => ({
                  ...progress,
                  errorMessage: 'Error uploading chat'
                }));
              });
          }
        };
        createChat();
      });

      createChatsByList
        .then(() => {
          setChatUploadProgress(({ errorMessage, ...progress }) => ({ ...progress, errorMessage: '' }));
          dispatch(chatsApi.util.resetApiState());
          setTimeout(() => {
            setCanCloseModal(() => true);
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          setChatUploadProgress(({ errorMessage, ...progress }) => ({
            ...progress,
            errorMessage: 'Error uploading chats'
          }));
        });
    },
    [addChatApi, dispatch, replaceChat]
  );

  useEffect(() => {
    if (decryptResponse !== null) {
      setTimeout(() => {
        runChatsUpload(decryptResponse);
      }, 500);
    }
  }, [decryptResponse, runChatsUpload]);

  return (
    <ModalSlide
      isOpened={true}
      blockClosing={!canCloseModal}
      modalClassNames={{ 'chat-uploads-modal': true }}
      hiddenClassName={'hidden-user-nav'}
      onModalClose={onModalClose}
      title="Uploading chats"
    >
      <div className="chats-uploads">
        <div className={clsx('chats-uploads-row chats-uploads-validating', !isUploading && 'inactive')}>
          <div className="loading-wrapper">
            <ProgressLoaderCircle progressPercentage={isUploading ? 2 : 100} showNumber={false} />
          </div>
          <div>{isUploading ? 'Validating' : 'Validated'}</div>
        </div>
        <div className={clsx('chats-uploads-row', isUploading && 'inactive')}>
          <div className="loading-wrapper">
            <ProgressLoaderCircle
              progressPercentage={progressPercents(chatUploadProgress.done, chatUploadProgress.total)}
              showNumber={false}
            />
          </div>
          <div className="chats-uploads-progress-info">
            {chatUploadProgress.total > 0 && chatUploadProgress.total === chatUploadProgress.done && (
              <div>Upload complete!</div>
            )}
            {!(chatUploadProgress.total > 0 && chatUploadProgress.total === chatUploadProgress.done) && (
              <>
                <div>Uploading</div>
                <div className="chats-uploads-current-chat-name">
                  {chatUploadProgress.workingOnChatName && `Chat "${chatUploadProgress.workingOnChatName}"`}
                </div>
              </>
            )}
          </div>
          <div className="chats-uploads-row">
            {chatUploadProgress.errorMessage && <div>Error: {chatUploadProgress.errorMessage}</div>}
          </div>
        </div>
        <div className="chats-uploads-files-progress"></div>
      </div>
    </ModalSlide>
  );
}

export default UploadProgressModal;
