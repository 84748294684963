import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../data/ui';
import { useChats } from '../hooks/useChats';
import { createEmptyFEChat } from '../data/chats-remote';
import { useNavigate } from 'react-router-dom';

const NewChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addChatApi: addChat, newChatIndex } = useChats();
  const [hasNewChat, setHasNewChat] = useState(newChatIndex !== null);
  const [buttonClsx, setButtonClx] = useState({ sideMenuButton: true, 'has-new-chat': hasNewChat });

  const onClick = useCallback(() => {
    addChat(createEmptyFEChat())
      .unwrap()
      .then(({ id }: { id: string }) => {
        dispatch(setShowMenu(false));
        navigate(`/chats/${id}`);
      });
  }, [dispatch, addChat, navigate]);

  useEffect(() => {
    setHasNewChat(() => newChatIndex !== null);
  }, [newChatIndex]);

  useEffect(() => {
    setButtonClx((classes) => ({ ...classes, 'has-new-chat': hasNewChat }));
  }, [hasNewChat]);

  return (
    <div className={clsx(buttonClsx)} onClick={onClick}>
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
