import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';

import './normal.css';
import './App.scss';
import HomePage from './pages/HomePage';
import { selectCurrentUser } from './data/ui';
import LoginForm from './components/login/LoginForm';

type RequireAuthType = {
  children: ReactElement<any, any> | null;
};

function RequireAuth({ children }: RequireAuthType) {
  const currentUser = useSelector(selectCurrentUser);
  return currentUser ? children : <Navigate to="login" />;
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          index
          path="/"
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route path="login" element={<LoginForm />} />
      </Routes>
    </div>
  );
}

export default App;
