import type { ChatLogType, ChatType, FileInChat } from '../chats-type';

export const DEFAULT_LLM_KEY = 'default';

export type ActionPayloadType = {
  chatLog: ChatLogType;
  uid: string;
  company: string;
  // fileIdList: Array<string>;
  files: Array<Omit<Omit<FileInChat, 'fileContent'>, 'selected'>>;
  chatId: string;
};

export type HandlesRequestInterface = {
  handlesRequest: {
    check: {
      (values: { inputPrompt: string } & ActionPayloadType): boolean;
    };
    buildActionParams: {
      (values: ActionPayloadType & { inputPrompt: string }): any;
    };
    preUpdateChat?: {
      (chat: ChatType): Promise<ChatType>;
    };
  };
};

export type BaseActionType = {
  command: string;
};

export type LlmAction = {
  (params: { message: string }): Promise<any>;
  onlyBrands?: Array<string>;
  command?: string;
  isModal?: boolean;
  apiBasedAccess?: boolean;
} & (HandlesRequestInterface | BaseActionType);

export interface LlmInterface {
  NAME: string;
  isAvailable(): boolean;
  actions: Array<LlmAction>;
  defaultAction: LlmAction;
  hasFileUploads: boolean;
}

export interface LlmsInterface {
  llm(name: string): LlmInterface;
  all(): Array<[string, LlmInterface]>;
}

export type CommandExecutor = {
  beforeReplaceChat: undefined | { (chat: ChatType): Promise<ChatType> };
  execute: { (): Promise<any> };
};
