import React, { useContext, useEffect, useRef } from 'react';
import { kebabCase, last } from 'lodash';
import Avatar from './Avatar';
import Loading from './Loading';
import BotResponse from './BotResponse';
import IntroSection from './IntroSection';
import { useRequest } from '../hooks/useRequest';
import { useChats } from '../hooks/useChats';
import { useInput } from '../hooks/useInput';
import type { ChatEntryType, ChatType } from '../data/chats-type';
import { SelectedChatContext } from '../context/SelectedChatContext';

export default function ChatLog() {
  const chatLogRef = useRef<HTMLDivElement>(null);

  const { replaceChat } = useChats();
  const { runInputPrompt } = useInput();
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const { stop, currentBotMessage, canStopResponse, setResponseFromAPI } = useRequest({ llmKey: chat?.llm });

  const handleStop = () => {
    const lastBotMessage = stop();
    if (lastBotMessage && chat) {
      // update latest log record with last bot message
      replaceChat({
        ...(chat as ChatType),
        chatLog: [
          ...chat.chatLog.slice(0, -1),
          { ...last(chat.chatLog), botMessage: lastBotMessage }
        ] as Array<ChatEntryType>
      })
        .unwrap()
        .then(() => {});
    }
  };

  const handleRegenerate = () => {
    if (chat) {
      const lastChatEntry = last<ChatEntryType>(chat.chatLog);
      const lastInputPrompt = lastChatEntry && lastChatEntry.chatPrompt;

      if (!lastInputPrompt || canStopResponse) {
        return;
      }

      setResponseFromAPI(true);

      runInputPrompt({
        inputPrompt: lastInputPrompt,
        modifyChatLog: (originChatLog) => {
          return originChatLog.slice(0, -1);
        }
      });
    }
  };

  useEffect(() => {
    if (canStopResponse) {
      setTimeout(() => {
        if (chatLogRef.current) {
          chatLogRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        }
      }, 10);
    }
  }, [currentBotMessage, canStopResponse]);

  const chatLog =
    !chat || !(chat.chatLog?.length > 0)
      ? []
      : chat.chatLog.map((messageLog, idx) => {
          const isLastMessage = idx === chat.chatLog.length - 1;
          const canRegenerate = isLastMessage;
          // starts with either /image or /web-search
          const isImageOrSearch = ['/image', '/web-search'].some((prefix) => messageLog.chatPrompt.startsWith(prefix));
          const isLoading = isImageOrSearch && messageLog.botMessage === 'Loading...';
          const showCopyBtn =
            !isLastMessage ||
            (isLastMessage &&
              (!canStopResponse || messageLog.botMessage !== 'Loading...') &&
              !messageLog.chatPrompt.startsWith('/image'));

          return (
            <div
              id={`navPrompt-${idx}-${kebabCase(chat.name.substring(0, 10))}`}
              className="chatLog"
              key={`${chat.name}:${idx}`}
              ref={chatLogRef}
            >
              <div className="chatPromptMainContainer">
                <div className="chatPromptPadding">
                  <div className="chatPromptWrapper">
                    <div className="user-avatar-wrapper">
                      <Avatar bg="#808080" className="userSVG">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={1.9}
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                          height={40}
                          width={40}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </Avatar>
                    </div>
                    <div className="chatPrompt">
                      <div className="text-element">{messageLog.chatPrompt}</div>
                    </div>
                    <div className="balance"></div>
                  </div>
                </div>

                <div className="botMessageMainContainer">
                  <div className="botMessageWrapper">
                    <div className="botMessage">
                      {isLastMessage ? (
                        isLoading ? (
                          <Loading />
                        ) : (
                          <BotResponse response={currentBotMessage || messageLog.botMessage} showCopy={showCopyBtn} />
                        )
                      ) : (
                        <BotResponse response={messageLog.botMessage} showCopy={showCopyBtn} />
                      )}
                    </div>
                    {isLastMessage && !isImageOrSearch && (
                      <>
                        {canStopResponse && !(chat?.files.length > 0) && (
                          <button className="stop-message" onClick={handleStop}>
                            Stop Streaming
                          </button>
                        )}
                        {!canStopResponse && canRegenerate && (
                          <button className="stop-message" onClick={handleRegenerate}>
                            Regenerate
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        });

  return chatLog.length > 0 ? <>{chatLog}</> : <IntroSection />;
}
