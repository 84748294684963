import { createSlice } from '@reduxjs/toolkit';
import { getInitialUser, setUserData } from '../../utils/auth';

type State = {
  currentSession: number;
  currentUser: any;
  searchResults: Array<{ id: string | number }> | null;
  searchQuery: string;
  showMenu: boolean;
  inputPrompt: string;
};

type HasUIState = {
  ui: State;
};

const initialState: State = {
  currentSession: 0,
  currentUser: getInitialUser(),
  searchResults: null,
  searchQuery: '',
  showMenu: false,
  inputPrompt: ''
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCurrentSession: (state, action) => {
      state.currentSession = action.payload;
    },
    setUser: (state, action) => {
      state.currentUser = action.payload;
      setUserData(action.payload);
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
    setInputPrompt: (state, action) => {
      state.inputPrompt = action.payload;
    }
  }
});

export const selectCurrentUser = (state: HasUIState) => state.ui.currentUser;

export const selectSearchResults = (state: HasUIState) => state.ui.searchResults;

export const selectSearchQuery = (state: HasUIState) => state.ui.searchQuery;

export const selectShowMenu = (state: HasUIState) => state.ui.showMenu;

export const selectInputPrompt = (state: HasUIState) => state.ui.inputPrompt;

export const { setCurrentSession, setUser, setSearchResults, setQuery, setShowMenu, setInputPrompt } = uiSlice.actions;
