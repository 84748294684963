import { makeRequest } from '../makeRequest';
import type { LlmAction, LlmInterface } from './types';

export const NAME = 'Claude';

export function isAvailable() {
  return true;
}

export function request(resource: string, payload = {}, options = {}) {
  return makeRequest(`${process.env.REACT_APP_BACKEND_URL}/claude${resource}`, payload, options);
}

// function generateImage(payload, options = {}) {
//   return request('/generate-image', payload, options);
// }
// generateImage.command = '/image';

function chat(payload = {}, options = {}) {
  return request('/chat', payload, options);
}

function dataOrganize(payload = {}, options = {}) {
  return request(
    '/data-organize',
    {
      ...payload,
      chatLog: [] // do not use full history log for data organize
    },
    options
  );
}
dataOrganize.command = '/data-organize';

function meetingScribe(payload = {}, options = {}) {
  return request(
    '/meeting-scribe',
    {
      ...payload,
      chatLog: [] // do not use full history log for data organize
    },
    options
  );
}
meetingScribe.command = '/meeting-scribe';

export const actions = {
  // generateImage,
  dataOrganize,
  meetingScribe,
  default: chat
};

class ClaudeLlm implements LlmInterface {
  get NAME(): string {
    return NAME;
  }

  isAvailable() {
    return isAvailable();
  }

  get actions() {
    return [dataOrganize, meetingScribe, chat] as unknown as LlmAction[];
  }

  get defaultAction() {
    return chat as unknown as LlmAction;
  }

  get hasFileUploads() {
    return false;
  }
}

export const claudeLLM = new ClaudeLlm();
