import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import ChatArea from '../components/ChatArea';
import PromptsMenuToggle from '../components/PromptsMenuToggle';
import ModalsBar from '../components/ModalsBar';
import createOutSideHandler from '../utils/hot-toggle';
import { selectCanStopResponse } from '../data/makeRequest';

function ChatsPage() {
  const canStopResponse = useSelector(selectCanStopResponse);
  const [promptSideMenuOpened, setPromptSideMenuOpened] = useState(false);
  const [promptModalShown, setPromptModalShown] = useState(false);
  const [promptSideMenuClsx, setPromptSideMenuClsx] = useState({
    disabled: canStopResponse,
    sideMenu: true,
    expandableSideMenu: true,
    opened: promptSideMenuOpened
  });

  const onPromptSideMenuToggleClick = useCallback(() => {
    setPromptSideMenuOpened((opened) => !opened);
  }, []);

  const onPromptModalToggle = useCallback((shown: boolean, finishedTask: boolean) => {
    setPromptModalShown(() => shown);
    if (finishedTask) {
      setPromptSideMenuOpened(() => shown);
    }
  }, []);

  const promptsSideMenuRef = useRef<RefObject<HTMLElement> | null>(null);

  useEffect(() => {
    setPromptSideMenuClsx((classObj) => ({ ...classObj, opened: promptSideMenuOpened }));
  }, [promptSideMenuOpened]);

  useEffect(() => {
    setPromptSideMenuClsx((classList) => ({ ...classList, disabled: canStopResponse }));
  }, [canStopResponse]);

  useEffect(() => {
    return createOutSideHandler(promptsSideMenuRef, () => {
      if (!promptModalShown) {
        setPromptSideMenuOpened(() => false);
      }
    });
  }, [promptsSideMenuRef, promptModalShown]);

  return (
    <div className={clsx('page', 'chats-page')}>
      <section className="chatBox">
        <ChatArea />
      </section>
      <aside ref={promptsSideMenuRef as React.LegacyRef<HTMLElement>} className={clsx(promptSideMenuClsx)}>
        <PromptsMenuToggle onToggleClick={onPromptSideMenuToggleClick} opened={promptSideMenuOpened} />
        <ModalsBar onModalToggle={onPromptModalToggle} />
      </aside>
    </div>
  );
}

export default ChatsPage;
