import { ChatType } from '../data/chats-type';

type MsNavigator = Navigator & {
  msSaveOrOpenBlob: {
    (blob: Blob, fileName: string): void;
  };
};

export type LegacyOpenChatsType = {
  downloadedAt: string;
  chatsData: Array<ChatType>;
};

export type CloseChatsType = {
  downloadedAt: string;
  data: string;
  re: string;
};

export const frontendDownloadFile = (fileName: string, downloadData: CloseChatsType) => {
  if (typeof window === 'undefined') {
    throw Error('This method can only be used in browser');
  }

  const downloadDataJSON = JSON.stringify(downloadData);
  const windowNavigator = window.navigator as Navigator | MsNavigator | undefined;

  if (windowNavigator && (windowNavigator as MsNavigator).msSaveOrOpenBlob) {
    let blob = new Blob([downloadDataJSON], { type: 'application/json' });
    (windowNavigator as MsNavigator).msSaveOrOpenBlob(blob, fileName);
  } else {
    let file = new File([downloadDataJSON], fileName, { type: 'application/json' });
    let exportUrl = URL.createObjectURL(file);
    const a = document.createElement('a');

    a.href = exportUrl;
    a.download = `${fileName}.json`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(exportUrl);
    document.body.removeChild(a);
  }
};

export const createFileReader = <DataType>(onAddChat: { (data: DataType): void }, onLoadEnd: { (): void }) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    try {
      onAddChat(JSON.parse(String(event.target?.result || '{}')));
    } catch (e) {
      console.error(e);
    }
  };
  reader.onloadend = () => {
    onLoadEnd();
  };
  return reader;
};
