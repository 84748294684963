import React, { useCallback, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import SvgComponent from '../components/SvgComponent';
import Avatar from '../components/Avatar';
import CopyToClipboardIcon from './CopyAndDownloadComponent';

type ParamsType = {
  response: string;
  showCopy: boolean;
};

const DALLE_IMAGE_URL_PREFIXES = ['https://oaidalleapi', 'https://dalleprodsec'];

const BotResponse = ({ response, showCopy = false }: ParamsType) => {
  const [isImageValid, setIsImageValid] = useState(true); // New state for image validity
  const isOaidalleapiURL = DALLE_IMAGE_URL_PREFIXES.some((prefix: string) => response.startsWith(prefix));
  const markdownRef = useRef(null);

  const onImageError = useCallback((e: { target: { style: { display: string } } }) => {
    console.error('Error loading image:', e);
    e.target.style.display = 'none';
    setIsImageValid(false); // Set image validity to false when an error occurs
  }, []);

  if (isOaidalleapiURL) {
    return (
      <div className="botMessageMainContainer">
        <div style={{ display: 'flex' }}>
          <div style={{ paddingTop: '16px' }}>
            <Avatar bg="#11a27f" className="openaiSVG">
              {/*<SvgComponent w={41} h={41} style={{ paddingTop: '10px' }} />*/}
              <SvgComponent w={41} h={41} />
            </Avatar>
          </div>
          <div className="botMessageWrapper">
            {isImageValid ? (
              <div className="oaidalleapi-image">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img
                  src={response}
                  alt="Oaidalleapi Image"
                  style={{ maxWidth: '100%', height: 'auto' }}
                  onError={onImageError as unknown as React.ReactEventHandler<HTMLImageElement>}
                />
              </div>
            ) : (
              <div className="image-expired-message">Image has expired</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bot-response">
      <div style={{ paddingTop: '16px' }}>
        <Avatar bg="#11a27f" className="openaiSVG">
          <SvgComponent w={41} h={41} />
        </Avatar>
      </div>
      <div className="text-element-wrapper">
        <div className="text-element" ref={markdownRef}>
          <ReactMarkdown
            remarkPlugins={[gfm]}
            components={{
              a: ({ node, children, ...props }) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#11a27f', textDecoration: 'underline' }}
                  {...props}
                >
                  {children}
                </a>
              ),
              table: ({ node, ...props }) => <table className="markdown-table" {...props} />
            }}
            className="markdown-container"
          >
            {response}
          </ReactMarkdown>
        </div>
      </div>
      <div className="bot-response-tools">{showCopy && <CopyToClipboardIcon htmlElementRef={markdownRef} />}</div>
    </div>
  );
};

export default BotResponse;
