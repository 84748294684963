import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import HomePage from './pages/HomePage';
import { Loading, AuthenticatedRoot } from './components/SecureRoute';
import LoginForm from './components/login/LoginForm';

import { oktaAuth } from './oktaConfig';

export default function OktaSecuredApp() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  if (!oktaAuth) {
    return null;
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <Routes>
          <Route path="login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
          <Route path="/" element={<AuthenticatedRoot />}>
            <Route path="" element={<HomePage />} />
            <Route path="chats/:chat_id" element={<HomePage />} />
          </Route>
          <Route path="login" element={<LoginForm />} />
        </Routes>
      </div>
    </Security>
  );
}
