import React, { ChangeEvent, useCallback, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import ModalSlide from '../ModalSlide';
import { useInput } from '../../hooks/useInput';
import { HRS_PAYROLL } from '../../data/llm/openai';
import SendIcon from '../SendIcon';

export type ParamsType = {
  onClose: {
    (): void;
  };
  onDone: {
    (done: boolean): void;
  };
};

type ModalParams = {
  modalTitle: string;
  modalDescription: string;
};

function DocsBot({ onClose, onDone }: ParamsType & ModalParams) {
  const [modalOpened, setModalOpened] = useState(true);
  const [textValue, setTextValue] = useState('');
  const [errorTextValue, setErrorTextValue] = useState('');
  const { runInputPrompt } = useInput();

  const onTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(() => e.target.value);
    setErrorTextValue(() => '');
  }, []);

  const onModalClose = useCallback(() => {
    setModalOpened(() => false);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();
      if (textValue) {
        const message = `${HRS_PAYROLL}\n${textValue}`;
        setModalOpened(() => false);
        runInputPrompt({ inputPrompt: message, onSent: () => onDone(true) });
      } else {
        setErrorTextValue(() => 'Please, add your message');
      }
    },
    [textValue, runInputPrompt, onDone]
  );

  return (
    <ModalSlide
      title="Payroll"
      isOpened={modalOpened}
      onModalClose={onModalClose}
      modalClassNames={{ 'docs-bot': true }}
    >
      <>
        <div className="modalslide-description">Anything about HR/Payroll</div>
        <form className="modalslide-form" onSubmit={onSubmit as unknown as React.FormEventHandler<HTMLFormElement>}>
          <div className="modalslide-form-group">
            <textarea
              className="modalslide-text"
              placeholder="Type your message here..."
              rows={5}
              onChange={onTextAreaChange}
              value={textValue}
            />

            {errorTextValue && (
              <div>
                <FaExclamation style={{ color: 'red', paddingRight: '5px', fontSize: '14px' }} />
                {errorTextValue}
              </div>
            )}
          </div>

          <div className="modalslide-form-buttons">
            <button type="submit" className="modalslide-submit">
              <span>Send</span>
              <span>
                <SendIcon size={22} strokeWidth={0} />
              </span>
            </button>
          </div>
        </form>
      </>
    </ModalSlide>
  );
}

export default DocsBot;
