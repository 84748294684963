import React, { useRef, useEffect, useContext } from 'react';
import ChatLog from './ChatLog';
import InputArea from './InputArea';
import LLMSwitcher from './LLMSwitcher';
import { ChatFileUploadProvider } from '../context/ChatFileUpload';
import { SelectedChatContext } from '../context/SelectedChatContext';

function ChatArea() {
  const wrapperRef = useRef(null);
  const logRef = useRef<HTMLDivElement>(null);
  const { selectedChat: chat } = useContext(SelectedChatContext);

  useEffect(() => {
    if (logRef.current && wrapperRef.current && chat) {
      logRef.current.scrollIntoView({
        block: chat.chatLog?.length > 0 ? 'end' : 'start'
      });
    }
  }, [logRef, wrapperRef, chat]);

  return (
    <ChatFileUploadProvider>
      <div className="chatLogWrapper" ref={wrapperRef}>
        <div ref={logRef}>
          <ChatLog />
        </div>
      </div>
      <div className="mainInputTools">
        <InputArea />
        <LLMSwitcher />
      </div>
    </ChatFileUploadProvider>
  );
}

export default ChatArea;
